import { Dialog, Slide, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { FileUploader } from "react-drag-drop-files";
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { updateService } from '../../apis/service';
import { getCategoryList } from '../../apis/category';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true
const fileTypes = ["JPG", "PNG", "JPEG"];

function EditService({ open, handleClose, editData }) {
    const queryClient = useQueryClient()
    const initialstate = {
        name: '',
        service_subtitle: '',
        category: '',
        image: null,
        image_preview: null,
        service_description: '',
        srvtype: [{
            Area: '',
            Plan: '',
            Price: '',
        }],
        service_view: 'FORM',
        service_showcase_image: null,
        service_showcase_image_preview: null,
        meta_title: '',
        meta_description: '',
        banner_image_alt: '',
        showcase_image_alt: ''
    }
    const [serviceData, setServiceData] = useState(initialstate)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    useEffect(() => {
        let data = {
            ...editData,
            image_preview: editData.image || null,
            service_showcase_image_preview: editData.service_showcase_image || null,
            srvtype: JSON.parse(editData.srvtype),
            category: editData.category_id
        }
        console.log(data);
        setServiceData(data)
    }, [editData])
    const handleInputChange = (e, type, name, object) => {
        let data;
        if (type === 'default') {
            let value = e.target.value
            data = {
                ...serviceData,
                [e.target.name]: value
            }
        } else if (type === 'file') {
            data = {
                ...serviceData,
                [name]: e,
                [name + '_preview']: URL.createObjectURL(e)
            }
        } else {
            const serviceArray = serviceData['srvtype']
            serviceArray[object.id][e.target.name] = e.target.value
            data = { ...serviceData }
        }
        setServiceData(data)
    }
    const postServiceDataToServer = useMutation((data) => updateService(data), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('servicelist')
                setIsSuccess(true)
                setTimeout(() => {
                    handleCloseAndReset()
                    handleClose()
                }, 2000)
            }
        },
        onError: () => {
            setIsError(true)
        }
    })
    const handleSubmitForm = (e) => {
        e.preventDefault()
        postServiceDataToServer.mutate(serviceData)
    }
    const handleAddServiceType = (type) => {
        const serviceArray = serviceData[type]
        serviceArray.push({
            Area: '',
            Plan: '',
            Price: '',
        })
        setServiceData({ ...serviceData })
    }
    const handleDeleteFieldsFromForm = (type, idx) => {
        const serviceArray = serviceData[type]
        serviceArray.splice(idx, 1)
        setServiceData({ ...serviceData })
    }
    const closeErrorMessage = () => {
        setIsError(false)
    }
    const handleCloseAndReset = () => {
        setServiceData(initialstate)
        closeErrorMessage()
    }
    const [categoryList, setCategoryList] = useState([])
    useQuery(['categoryList'], getCategoryList, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setCategoryList(data.data)
        }
    })

    const handleuploadAgain = (name) => {
        let data = {
            ...serviceData,
            [name]: null,
            [name + '_preview']: null
        }
        setServiceData(data)
    }
    useEffect(() => {
        isError && setTimeout(() => setIsError(false), [4000])
    }, [isError])
    return (
        <>
            <Dialog fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
                <div className='container_xxl'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Edit Services</h1>
                        </div>
                    </div>
                    <div className='form-body py-8'>
                        <form className=' space-y-4' autoComplete='off' onSubmit={handleSubmitForm}>
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Service Name <small className="text-red-700">*</small></label>
                                    <input required={IS_REQUIRED} type="text" placeholder='Product Name' name='name' className='input' value={serviceData.name} onChange={(e) => handleInputChange(e, 'default')} />
                                </div>
                                <div>
                                    <label htmlFor="Service Subtitle" className='label'>Service Subtitle <small className="text-red-700">*</small></label>
                                    <input required={IS_REQUIRED} type="text" placeholder='Service Subtitle' name='service_subtitle' className='input' value={serviceData.service_subtitle} onChange={(e) => handleInputChange(e, 'default')} />
                                </div>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Select Category <small className="text-red-700">*</small></label>
                                    <select required={IS_REQUIRED} name="category" className='input' value={serviceData.category} onChange={(e) => handleInputChange(e, 'default')}>
                                        <option value="">--Select Category--</option>
                                        {categoryList.map((list) => {
                                            const { id, name } = list
                                            return <option key={id} value={id}>{name}</option>
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Select Service View <small className="text-red-700">*</small></label><small className='text-red-700'>(will reflect as selected on the front end)</small>
                                    <select required={IS_REQUIRED} name="service_view" className='input' value={serviceData.service_view} onChange={(e) => handleInputChange(e, 'default')}>
                                        <option value="GRID">Grid</option>
                                        <option value="FORM">Form</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Product Name" className='label'>Service Description <small className="text-red-700">*</small></label>
                                <textarea required={IS_REQUIRED} name="service_description" className="input min-h-[6rem]" placeholder="Enter description" value={serviceData.service_description} onChange={(e) => handleInputChange(e, 'default')}></textarea>
                                <small className="text-gray-600 capitalize font-semibold">Note: press enter to break the line</small>
                            </div>
                            <div>
                                <div>
                                    <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                        <div className='pb-3'>
                                            <h1 className='font-semibold text-2xl'>SEO</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 gap-4'>
                                    <div>
                                        <label htmlFor="SKU" className='label'>Meta Title</label>
                                        <input type="text" placeholder='Meta Title' name='meta_title' value={serviceData['meta_title']} onChange={(e) => handleInputChange(e, 'default')} className='input' />
                                    </div>
                                    <div>
                                        <label htmlFor="Price" className='label'>Meta Description</label>
                                        <textarea placeholder='Meta Description' name='meta_description' className='input' value={serviceData['meta_description']} onChange={(e) => handleInputChange(e, 'default')}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Product Name" className='label'>Service Banner Image</label> <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                <div className=' space-y-4'>
                                    {!serviceData.service_showcase_image_preview ?
                                        <FileUploader name="service_showcase_image" types={fileTypes} classes="file-uploader" handleChange={(e) => handleInputChange(e, 'file', 'service_showcase_image')} hoverTitle='Drop here' />
                                        : <div className=' border border-dashed border-blue-700 rounded'>
                                            <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                <img src={serviceData.service_showcase_image_preview} alt="preview" />
                                                <Button variant="outlined" onClick={() => handleuploadAgain('service_showcase_image')}>Re-Upload</Button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Image Alt" className='label'>Banner Image alt</label>
                                <input type="text" placeholder='Enter Banner image alt' value={serviceData?.banner_image_alt} onChange={(e) => handleInputChange(e, 'default')} name='banner_image_alt' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Product Name" className='label'>Service Showcase Image</label> <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                <div className=' space-y-4'>
                                    {!serviceData.image_preview ?
                                        <FileUploader name="product_thumbnail" types={fileTypes} classes="file-uploader" handleChange={(e) => handleInputChange(e, 'file', 'image')} hoverTitle='Drop here' />
                                        : <div className=' border border-dashed border-blue-700 rounded'>
                                            <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                <img src={serviceData.image_preview} alt="preview" />
                                                <Button variant="outlined" onClick={() => handleuploadAgain('image')}>Re-Upload</Button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Image Alt" className='label'>Showcase Image alt</label>
                                <input type="text" placeholder='Enter Showcase image alt' value={serviceData?.showcase_image_alt} onChange={(e) => handleInputChange(e, 'default')} name='showcase_image_alt' className='input' />
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Service Type</h1>
                                    <div onClick={() => handleAddServiceType('srvtype')}>
                                        <IconButton>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className=' space-y-7'>
                                    {serviceData?.srvtype.map((object, idx) => {
                                        const { Area, Plan, Price } = object
                                        return <div className={`grid grid-cols-3 gap-4`} key={idx}>
                                            <div>
                                                <label htmlFor="area" className='label'>Area</label>
                                                <input type="text" placeholder='Area' name='Area' className='input' value={Area} onChange={(e) => handleInputChange(e, 'object', '', { id: idx })} />
                                            </div>
                                            <div>
                                                <label htmlFor="plan" className='label'>Plan</label>
                                                <input type="text" placeholder='Plan' name='Plan' className='input' value={Plan} onChange={(e) => handleInputChange(e, 'object', '', { id: idx })} />
                                            </div>
                                            <div className='flex items-center justify-between divide-x'>
                                                <div className='flex-1 pr-2'>
                                                    <label htmlFor="price" className='label'>Price</label>
                                                    <input type="text" placeholder='Price' name='Price' className='input' value={Price} onChange={(e) => handleInputChange(e, 'object', '', { id: idx })} />
                                                </div>
                                                {idx >= 1 &&
                                                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteFieldsFromForm('srvtype', idx)}>
                                                        <IconButton color="error" aria-label="add an delete" size="large">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{postServiceDataToServer.error.message}, Please try again later</Alert>}
                            {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Service Updated Succesfully</Alert>}
                            <div className='text-center my-6'>
                                {postServiceDataToServer.isLoading ?
                                    <Spinner /> :
                                    <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                        Save Service
                                    </Button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default EditService