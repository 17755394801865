import { Dialog, Slide, Button, Switch } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useRef, useState } from 'react'
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Editor from '../../components/elements/Editor';
import useCreate from '../../hooks/useCreate';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment'
import deBounce from '../../hooks/useDebounce';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true

function UpdateBlogs({ open, handleClose, singleBlogData }) {
    const [blogFormData, setBlogFormData] = useState(singleBlogData)
    const handleBlogFormData = (e) => {
        let $this = e.target
        if ($this.name == "blogTitle") {
            let newval = $this.value
            newval = newval.replace(/\s+/g, '-').toLowerCase();
            let sanval = sanitizetext(newval)
            let data = {
                ...blogFormData,
                blogSlug: sanval,
                blogTitle: $this.value
            }
            setBlogFormData({ ...data })
            setSlug({ ...data })
            let senddata = {
                blogSlug: sanval
            }
            deBounce((e) => callapi(e, senddata), senddata)



        } else {
            let data = {
                ...blogFormData,
                [e.target.name]: $this.value
            }
            setBlogFormData({ ...data })
        }
        // let data = {
        //     ...blogFormData,
        //     [e.target.name]: $this.value
        // }
        // setBlogFormData({ ...data })
    }
    const fileInputRef = useRef(null)

    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [slug, setSlug] = useState()
    const [slugCheck, setSlugCheck] = useState()
    const [imagePreview, setImagePreview] = useState('')
    const { setDataToServer, } = useCreate({
        refreshUrl: 'blogsList',
        url: 'blogsUpdate',
        onSuccess: () => {
            setIsSuccess(true)
        },
        onError: () => {
            setIsError(true)
        }
    })

    const { setDataToServer: checkSlugData } = useCreate({
        refreshUrl: '',
        url: 'checkSlugData',
        onSuccess: (data) => {
            setSlugCheck(false)
            // setIsSuccess(true)
        },
        onError: (err) => {
            setSlugCheck(true)
            // setIsError(true)
        }
    })

    const slugInput = (e) => {
        let val = e.target.value
        val = val.replace(/\s+/g, '-').toLowerCase();
        let sanval = sanitizetext(val)
        let data = {
            ...blogFormData,
            blogSlug: sanval
        }
        // setSlug(sanval)
        setBlogFormData({ ...data })
        setSlug({ ...data })
        let senddata = {
            blogSlug: sanval
        }
        deBounce((e) => callapi(e, senddata), senddata)
    }

    const callapi = (e, data) => {
        checkSlugData.mutate(data)
    }


    const handleSubmitBlogFormData = (e) => {
        e.preventDefault()
        setDataToServer.mutate(blogFormData)
    }

    const handleChangeEditor = (e, value) => {
        let data = {
            ...blogFormData,
            blogContent: value
        }
        setBlogFormData({ ...data })
    }
    const closeErrorMessage = () => {
        setIsSuccess(false)
        setIsError(false)
    }

    const handleOpenFileUploadDialog = () => {
        fileInputRef.current.click()
    }

    const handleChangeImageFile = (e) => {
        let file = e.target.files[0]
        const objectUrl = URL.createObjectURL(file)
        setImagePreview(objectUrl)
        let data = {
            ...blogFormData,
            blogBannerImage: file
        }
        setBlogFormData(data)
    }

    const handleBlogStatus = (e) => {
        let status = e.target.checked
        let data = {
            ...blogFormData,
            blogStatus: status
        }
        setBlogFormData(data)
    }


    const handleSetPublishDate = (value) => {
        let data = {
            ...blogFormData,
            blogPublishedAt: moment(new Date(value)).unix()
        }
        setBlogFormData({ ...data })
    }

    function sanitizetext(intext) {
        const noSpecialChars = intext.replace(/[^a-zA-Z0-9\-]/g, '');
        // urlcheck(noSpecialChars)
        return noSpecialChars
    }

    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <div className='container_xxl'>
                <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading'>Update Blog</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    <form className='' autoComplete='off' onSubmit={handleSubmitBlogFormData}>
                        <div className='grid grid-cols-1 gap-4'>
                            <div>
                                <label htmlFor="Blog Title" className='label'>Blog Title <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Blog Title' value={blogFormData.blogTitle} onChange={handleBlogFormData} name='blogTitle' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Blog Title" className='label'>Blog Slug <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Blog Slug' value={blogFormData.blogSlug} onChange={slugInput} name='blogSlug' className='input' />
                                {slugCheck ? <p class="slugerror text-red-600 text-base mt-6 mb-6 font-semibold" id="slugerror">Slug already exist</p> : ""}
                            </div>
                            <div>
                                <label htmlFor="Blog Title" className='label'>Blog Publish Date <small className="text-red-700">*</small></label>
                                <ReactDatePicker dateFormat="dd/MM/yyyy" required={IS_REQUIRED} selected={new Date(blogFormData.blogPublishedAt * 1000)} onChange={(date) => handleSetPublishDate(date)} className='input' />
                            </div>
                            <div>
                                <label htmlFor="Blog Title" className='label mr-4'>Blog Status</label>
                                <span className='text-gray-700 text-sm'>Hide</span>
                                <Switch checked={blogFormData?.blogStatus} inputProps={{ 'aria-label': 'controlled' }} onChange={handleBlogStatus} className='hide_show_switch' />
                                <span className='text-gray-700 text-sm'>Show</span>
                            </div>
                            <div>
                                <label htmlFor="Blog Title" className='label'>Blog Banner Image <small className="text-red-700">*</small></label>
                                {(imagePreview || blogFormData?.blogBannerImage) && <img src={imagePreview || blogFormData?.blogBannerImage} alt="blog image" className='object-cover my-4' />}
                                <div>
                                    <Button variant="contained" color='success' className='primaryBtn-contained' onClick={handleOpenFileUploadDialog}>
                                        {(imagePreview || blogFormData?.blogBannerImage) ? 'Update Banner Image' : 'Add Banner Image'}
                                    </Button>
                                    <input ref={fileInputRef} type="file" className='hidden' onChange={handleChangeImageFile} />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Image Alt" className='label'>Image alt</label>
                                <input type="text" placeholder='Enter image alt' value={blogFormData.image_alt} onChange={handleBlogFormData} name='image_alt' className='input' />
                            </div>
                            <div>
                                <label htmlFor="Blog Title" className='label'>Blog Content <small className="text-red-700">*</small></label>
                                <Editor value={blogFormData?.blogContent} onChange={handleChangeEditor} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <div className='pb-3'>
                                        <h1 className='font-semibold text-2xl'>SEO</h1>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <label htmlFor="SKU" className='label'>Meta Title</label>
                                    <input type="text" placeholder='Meta Title' name='meta_title' value={blogFormData['meta_title']} onChange={handleBlogFormData} className='input' />
                                </div>
                                <div>
                                    <label htmlFor="Price" className='label'>Meta Description</label>
                                    <textarea placeholder='Meta Description' name='meta_description' className='input' value={blogFormData['meta_description']} onChange={handleBlogFormData}></textarea>
                                </div>
                            </div>
                        </div>
                        {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.message}, Please try again later</Alert>}
                        {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Blog Updated Succesfully</Alert>}
                        <div className='text-center my-6'>
                            {false ? <Spinner /> :
                                <Button disabled={slugCheck ? true : false} id="savebutton" type='submit' variant="contained" className='primaryBtn-contained'>
                                    Publish Blog
                                </Button>}
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default UpdateBlogs