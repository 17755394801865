import { useQuery } from "@tanstack/react-query";

import { useState } from 'react'
import axios from "../apis/axios";
import { TOKEN_KEY } from "../config";
let TOKEN = sessionStorage.getItem(TOKEN_KEY);

function useRead({url,initialData}) {
  const [paramsObject, setGetListParams] = useState(initialData)
  const queryData = useQuery([url, paramsObject], () => getUsers(paramsObject), {
    refetchOnWindowFocus: false
  })
  const getUsers = async (paramsObject) => {
    let fd = new FormData()
    for (var key in paramsObject) {
      fd.append(key, paramsObject[key]);
    }
    const response = await axios({
      method: "POST",
      data: fd,
      url: `/${url}`,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data
  }
  return { paramsObject, setGetListParams, queryData }

}

export default useRead