import Layout from "../../components/layout"
import CardWidgets from "../../components/widgets/CardWidgets"
import { useQuery } from "@tanstack/react-query";
import { getDashboardData } from "../../apis/dashboard";
import Spinner from "../../components/spinner";
import Errors from "../errors";
function Dashboard() {
    const handleGetDashboardData = useQuery(['categoryList'], getDashboardData, {
        refetchOnWindowFocus: true
    })
    return (
        <>
            <Layout>
                {handleGetDashboardData.isLoading ? <Spinner />
                    : handleGetDashboardData.status === "error" ? <Errors errorObject={handleGetDashboardData.error} inline /> :
                        <div className="grid grid-cols-4 gap-x-5 mt-8">
                            <CardWidgets
                                label={'orders'}
                                description={'Number of orders On the ultima Search website'}
                                border={` border-red-700`}
                                count={handleGetDashboardData.data.data.total_orders || 0}
                            />
                            <CardWidgets
                                label={'customers'}
                                description={'Number of customers on the ultima search website'}
                                border={` border-blue-700`}
                                count={handleGetDashboardData.data.data.total_customers || 0}
                            />
                            <CardWidgets
                                label={'products'}
                                description={'Number of products on the Ultima search website'}
                                border={` border-lime-700`}
                                count={handleGetDashboardData.data.data.total_products || 0}
                            />
                            <CardWidgets
                                label={'revenue'}
                                description={'Revenue generated by the ultima Search website'}
                                border={` border-yellow-700`}
                                count={handleGetDashboardData.data.data.total_paid_orders || 0}
                            />
                        </div>}
            </Layout>
        </>
    )
}

export default Dashboard